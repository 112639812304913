body.login {
	background: #FFF;
	h1 {
		a {
			width: 140px;
			height: 94px;
			background: url(../img/logo.png) no-repeat center center / contain;
		}
	}
	form {
		box-shadow: none;
	}
}

#login {
	background: #FFF;
	box-shadow: none;
}